import { ASSET_REDIRECT_TARGET } from "projects/sso/src/app/data";

export const environment = {
    e2e: false,
    production: true,
    firebase: {
        apiKey: "AIzaSyCTD3afByl2xd_iJ9sxJlCAwACTlwTlm5c",
        authDomain: "nintendo.cwl.camp"
    },
    region: "ToDOToBeDeleted",
    brain_base_url: "https://nintendo.api.deloitte.camp",
    authParams: ["google"],
    signInType: 'signInWithPopup',
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle asset questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            deloitteAiCatalog: "Gen AI",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "Storefront",
            orderHistory: "Order History",
            myEntitlements: "My Entitlements"
        }
    },
    aoh_url: "https://aoh.nintendo.cwl.camp",
    store_url: "https://nintendo.cwl.camp",
    api_base_url: "https://nintendo.api.deloitte.camp",
    hasEnterpriseAssetSearch: false,
    asset_redirect_uri: {},
    onboarding: {
        displayVideo: true,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:mahorst@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "mailto:mahorst@deloitte.de"
    },
    onboarding_options: {
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
